import { useMsal } from '@azure/msal-react'
import {
  acquireAccessToken,
  CenteredDiv,
  ErrorMessage,
  CustomDataGrid,
  useWtxLocalization,
  PersistantFilterDiv
} from '@wavetronix/common-components'
import { useState } from 'react'
import axios from 'axios'
import React from 'react'
import { useQuery } from '@tanstack/react-query'
import { env } from '../index.js'
import LicensesFilterDrawer, { DEFAULT_LICENSES_FILTER, filterLicenses } from './drawers/LicensesFilterDrawer'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'

function groupLicensesByItemNumber(list) {
  const resultObject = list.reduce((acc, value) => {
    // Group initialization
    if (!acc[value.licenseDefinition.itemNumber]) {
      acc[value.licenseDefinition.itemNumber] = []
    }
    // Grouping
    acc[value.licenseDefinition.itemNumber].push(value)
    return acc
  }, {})
  const resultList = Object.entries(resultObject).map(x => ({
    itemNumber: x[0],
    licenses: x[1],
    name: x[1][0].licenseDefinition.name,
    description: x[1][0].licenseDefinition.description
  }))
  return resultList
}

const getLicenses = async token =>
  await axios
    .get(`${env.urls.updatesURL}/api/v1/licenses/mine?state=All`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then(res =>
      groupLicensesByItemNumber(res.data).map((g, i) => ({
        ...g,
        active: g.licenses.filter(u => u.activation.isActive).length,
        available: g.licenses.filter(u => !u.activation.isActive).length,
        key: `grouped-licenses-${i}`,
        id: g.itemNumber
      }))
    )

export default function LicensesTable() {
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'))
  let largeScreenFeaturesActive = !isSmallScreen

  const { instance, accounts } = useMsal()
  let localizedStrings = useWtxLocalization()
  const [filter, setFilter] = useState(DEFAULT_LICENSES_FILTER)

  const columns = [
    {
      field: 'name',
      headerName: localizedStrings.name,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      field: 'itemNumber',
      headerName: localizedStrings.itemNumber,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      hide: isSmallScreen
    },
    {
      field: 'description',
      headerName: localizedStrings.description,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      field: 'active',
      headerName: localizedStrings.applied,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    },
    {
      field: 'available',
      headerName: localizedStrings.available,
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: largeScreenFeaturesActive
    }
  ]

  async function getUsersLicenses() {
    let token = await acquireAccessToken(instance, accounts, env)
    return await getLicenses(token)
  }
  const { isLoading, data, error } = useQuery({ queryKey: ['getLicenses'], queryFn: getUsersLicenses })

  if (error) {
    return (
      <CenteredDiv>
        <ErrorMessage error={error} />
      </CenteredDiv>
    )
  }

  return (
    <PersistantFilterDiv
      defaultOpen={largeScreenFeaturesActive}
      resetFilter={() => setFilter(DEFAULT_LICENSES_FILTER)}
      drawer={<LicensesFilterDrawer filter={filter} setFilter={setFilter} />}
      page={
        <div
          style={{
            width: '100%',
            overflow: 'auto',
            fontFamily: 'Proxima Nova',
            paddingLeft: '15px',
            marginTop: '15px'
          }}>
          <CustomDataGrid columns={columns} rows={data ? filterLicenses(filter, data) : []} loading={isLoading} />
        </div>
      }
    />
  )
}
