import { CenteredDiv, WtxColors, SetupProvider, createWtxAuthInstance } from '@wavetronix/common-components'
import React from 'react'
import { createRoot } from 'react-dom/client'
import MainPage from './components/MainPage'
import './index.css'
import generateEnv from './env/generateEnv'
import { en } from './lang/en.js'
import { fr } from './lang/fr.js'

if (window.location.pathname === '/logout') {
  window.localStorage.clear()
  window.location.href = window.location.origin
}

export const env = await generateEnv()

const container = document.getElementById('root')
const root = createRoot(container) // createRoot(container!) if you use TypeScript

if (env.isError) {
  root.render(
    <CenteredDiv>
      <h3 style={{ color: WtxColors.INNOVATION_RED }}>{`${env.message}`}</h3>
    </CenteredDiv>
  )
} else {
  const msalInstance = await createWtxAuthInstance(env)

  root.render(
    <SetupProvider
      env={env}
      msalInstance={msalInstance}
      languages={{
        en: en,
        fr: fr
      }}
      allowedRoles={['Expanse Admin', 'Approved Customer', 'Wavetronix Employee', 'Licensing Admin']}>
      <MainPage />
    </SetupProvider>
  )
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
