import { TextField } from '@mui/material'

export const DEFAULT_LICENSES_FILTER = {
  name: '',
  itemNumber: '',
  description: ''
}

export const filterLicenses = (filter, licenses) =>
  licenses
    ? licenses.filter(
        p =>
          (p.id.toLowerCase().includes(filter.itemNumber.toLowerCase()) || filter.itemNumber === '') &&
          (p.name.toLowerCase().includes(filter.name.toLowerCase()) || filter.name === '') &&
          (p.description.toLowerCase().includes(filter.description.toLowerCase()) || filter.description === '')
      )
    : []

export default function LicensesFilterDrawer({ filter, setFilter }) {
  const handleChange = (id, value) => setFilter(f => ({ ...f, [id]: value }))

  return (
    <>
      <TextField
        label='Name'
        style={{ width: '100%' }}
        onChange={e => handleChange('name', e.target.value)}
        value={filter.name}
        variant='outlined'
        size='small'
      />
      <TextField
        label='Item Number'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => handleChange('itemNumber', e.target.value)}
        value={filter.itemNumber}
        variant='outlined'
        size='small'
      />
      <TextField
        label='Description'
        style={{ width: '100%', marginTop: '15px' }}
        onChange={e => handleChange('description', e.target.value)}
        value={filter.description}
        variant='outlined'
        size='small'
      />
    </>
  )
}
