import { HasAccess } from '@wavetronix/common-components'
import LicensesTable from './LicensesTable'
import LicensesTableV2 from './LicensesTableV2'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Switch } from '@mui/material'
import { env } from '../index.js'

export default function LicensePage() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [useDeprecated, setUseDeprecated] = useState(searchParams.get('deprecated') ? true : false)

  return (
    <>
      <HasAccess allowedRoles={['Wavetronix Employee']} env={env}>
        <div style={{ width: '90%', margin: '10px', fontStyle: 'italic' }}>
          <Switch
            color='primary'
            checked={useDeprecated}
            onChange={e => {
              setUseDeprecated(e.target.checked)
              setSearchParams(`deprecated=${e.target.checked}`)
            }}
          />
          Use License V1 (Deprecated)
        </div>
      </HasAccess>
      {useDeprecated === false ? <LicensesTableV2 /> : <LicensesTable />}
    </>
  )
}
